<template>
  <div class="Main" style="overflow-y: scroll">
    <div class="tableOne">
      <div class="search_and_profil"></div>

      <div class="title_boxx">
        <div>
          <h2>Шаблон бейджика</h2>
        </div>
      </div>

      <div class="table_box shablon_in">
        <div class="table_title">
          <h3>Шаблон</h3>
        </div>
        <div class="Form_box">
          <div class="form_box">
            <b-form @submit.prevent="uploadShablon" class="w-100">
              <div class="form_in">
                <!-- User name -->
                <div
                    class="boxx"
                    v-for="(element, index) in Object.keys(elements)"
                    :key="index"
                >
                  <b-form-group
                      :label="elements[element].title"
                      class="title"
                      :class="{'only-label':elements[element].hideContent}"
                      :label-for="element"
                  >
                    <b-form-input
                        v-if="!elements[element].hideContent"
                        class="inputt"
                        :id="element"
                        required
                        @input="(e) => onInput(e)"
                        v-model="elements[element].name"
                    />
                  </b-form-group>
                  <b-form-group
                      label="X"
                      class="title"
                      :label-for="element + '_x'"
                  >
                    <b-form-input
                        :id="element + '_x'"
                        required
                        class="axis-input"
                        min="0"
                        type="number"
                        v-model="elements[element].x_axis"
                    />
                  </b-form-group>
                  <b-form-group
                      label="Y"
                      class="title"
                      :label-for="element + '_y'"
                  >
                    <b-form-input
                        :id="element + '_y'"
                        required
                        min="0"
                        class="axis-input"
                        type="number"
                        v-model="elements[element].y_axis"
                    />
                  </b-form-group>
                  <b-form-group
                      :label="!elements[element].hideContent?'Color':''"
                      class="title"
                      :label-for="element + '_color'"
                  >
                    <input
                        v-if="!elements[element].hideContent"
                        :id="element + '_color'"
                        style="height: 45px; border: 1px solid #ced4da;"
                        class="axis-input"
                        type="color"
                        v-model="elements[element].color"
                    />
                  </b-form-group>
                  <b-form-group
                      :label="!elements[element].hideContent?'Font size':''"
                      class="title"
                      :label-for="element + '_size'"
                  >
                    <b-form-input
                        v-if="!elements[element].hideContent"
                        :id="element + '_size'"
                        class="axis-input"
                        type="number"
                        v-model="elements[element].font_size"
                    />
                  </b-form-group>
                  <br/>
                  <b-form-group
                      label="Enable"
                      class="title"
                      :label-for="element + '_size'"
                  >
                    <input
                        type="checkbox"
                        :id="element + '_size'"
                        v-model="elements[element].is_enabled"
                    />
                  </b-form-group>
                </div>

                <!-- User Position -->

                <!-- Save File -->
                <!--                <img v-if="src" :src="src" class="logotype" alt="org_logo"/>-->
                <!--                <div class="boxx">-->
                <!--                  <div class="title">-->
                <!--                    <div>Профиль</div>-->
                <!--                    <label for="file" class="file">-->
                <!--                      <div class="icon">-->
                <!--                        <b-icon-->
                <!--                            icon="upload"-->
                <!--                            style="width: 25px; height: 25px; color: #999999"-->
                <!--                        ></b-icon>-->
                <!--                      </div>-->
                <!--                      <div class="icon">-->
                <!--                        <h4>Перетащите сюда <br/>или</h4>-->
                <!--                      </div>-->
                <!--                      <div class="icon">-->
                <!--                        <div class="link">Загружаете Файл</div>-->
                <!--                        <b-form-file-->
                <!--                            id="file"-->
                <!--                            class="file_save"-->
                <!--                            @change="onFileChange"-->
                <!--                            v-model="file"-->
                <!--                        />-->
                <!--                      </div>-->
                <!--                    </label>-->
                <!--                  </div>-->
                <!--                  <div></div>-->
                <!--                </div>-->
                <!-- Organization -->

                <!-- Btn box -->
                <div class="btn_box">
                  <button class="btn_box" type="submit">Сохранить</button>
                </div>
              </div>
            </b-form>
          </div>
          <div class="form_cart">
            <div class="cart_box">
              <div ref="constructorContainer">
                <template v-for="(element, index) in Object.keys(elements)">
                  <div
                      :key="index"
                      v-if="!elements[element].hideContent && !busy"
                      class="beyjXCenter"
                      :style="{
                      top: elements[element].y_axis + 'px',
                      left: elements[element].x_axis + 'px',
                      color: elements[element].color,
                      fontSize:elements[element].font_size+'px'
                    }"
                      @mouseup="onMouseUp"
                      @mousemove="(e) => onMouseMove(e, element)"
                      @mousedown="onMouseDown"
                  >
                    {{ elements[element].name }}
                  </div>
                </template>

                <img v-if="!busy"
                     draggable="false"
                     class="beyjLogo beyjXCenter"
                     src="@/assets/ava.jpeg"
                     @mouseup="onMouseUp"
                     @mousemove="(e) => onMouseMove(e, 'organization')"
                     @mousedown="onMouseDown"
                     :style="{
                    top: elements.organization.y_axis + 'px',
                    left: elements.organization.x_axis + 'px'
                  }">
                <img
                    v-if="src && frontSrc"
                    draggable="false"
                    @mouseup="onMouseUp"
                    @mousemove="(e) => onMouseMove(e, 'organization')"
                    @mousedown="onMouseDown"
                    class="beyjLogo beyjXCenter"
                    :style="{
                    top: elements.organization.y_axis + 'px',
                    left: elements.organization.x_axis + 'px'
                  }"
                    :src="src"
                    alt="profile"
                />
                <img
                    v-if="frontSrc"
                    :src="frontSrc"
                    alt="forntImg"
                    class="beyj"
                />
                <img v-if="!frontSrc" src="@/assets/dummy.png" class="beyj"/>
                <input
                    type="file"
                    ref="front"
                    style="display: none"
                    @input="frontSetter"
                />
              </div>
              <button @click="front" class="uploadBtn">Открыть шаблон</button>
              <div>
                <img v-if="backSrc" :src="backSrc" alt="backImg" class="beyj"/>
                <img v-if="!backSrc" src="@/assets/dummy.png" class="beyj"/>
                <input
                    type="file"
                    ref="back"
                    style="display: none"
                    @input="backSetter"
                />
              </div>
              <button @click="back" class="uploadBtn">Открыть шаблон</button>
            </div>
          </div>
        </div>
        <b-overlay :show="busy" no-wrap></b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
// import {mapMutations, mapGetters, mapState} from 'vuex';
import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BImg,
  BButton,
  BIcon,
  BOverlay,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import { instance } from "@/utils/composables";

export default {
  components: {
    // ToastificationContent,
    BTable,
    BRow,
    BCol,
    BIcon,
    BPagination,
    BImg,
    BButton,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      src: null,
      elements: {
        organization: {
          title: "Профиль фото",
          name: "",
          hideContent: true,
          x_axis: 0,
          y_axis: 0,
          color: '',
          font_size: 16,
          is_enabled: false
        },
        firstName: {
          title: "Имя",
          name: "Firstname",
          hideContent: false,
          y_axis: 15,
          x_axis: 0,
          color: '',
          font_size: 16,
          is_enabled: false
        },
        secondName: {
          title: "Фамилия",
          name: "Lastname",
          hideContent: false,
          x_axis: 0,
          y_axis: 0,
          color: '',
          font_size: 16,
          is_enabled: false
        },
        company: {
          title: "Организация",
          name: "Organization",
          hideContent: false,
          x_axis: 0,
          y_axis: 0,
          color: '',
          font_size: 16,
          is_enabled: false
        },
        position: {
          title: "Позиция",
          name: "Position",
          hideContent: false,
          x_axis: 0,
          y_axis: 0,
          color: '',
          font_size: 16,
          is_enabled: false
        },
        id: {
          title: "Публичный ID",
          name: "ID12345678",
          hideContent: false,
          x_axis: 0,
          y_axis: 0,
          color: '',
          font_size: 16,
          is_enabled: false
        },
      },

      file: null,
      options: [
        {
          text: "Center",
          value: "center",
        },
        {
          text: "Left",
          value: "left",
        },
        {
          text: "Right",
          value: "right",
        },
      ],
      respondedData: null,
      busy: true,
      frontFile: null,
      frontSrc: null,
      backFile: null,
      backSrc: null,
      allData: null,

      startY: 0,
      startX: 0,
      mousePosition: null,
      offset: [0, 0],
      movingEl: null,
    };
  },
  methods: {
    onInput(element) {
      console.log(element);
    },
    onMouseUp(e) {
      this.movingEl.style.zIndex = 1;
      this.movingEl = null;
    },
    onMouseDown(e) {
      // console.log(e)
      this.movingEl = e.currentTarget;
      this.movingEl.style.zIndex = 2;
      this.offset = [
        e.currentTarget.offsetLeft - e.clientX,
        e.currentTarget.offsetTop - e.clientY,
      ];
    },
    onMouseMove(e, elementName) {
      if (this.movingEl === e.currentTarget) {
        this.elements[elementName].x_axis = e.clientX + this.offset[0];
        this.elements[elementName].y_axis = e.clientY + this.offset[1];
      }
    },
    // onFileChange(e) {
    //   this.file = e.target.files[0];
    //   this.src = URL.createObjectURL(this.file);
    // },
    front() {
      this.$refs.front.click();
    },
    back() {
      this.$refs.back.click();
    },
    frontSetter(e) {
      this.frontFile = e.target.files[0];
      this.frontSrc = URL.createObjectURL(this.frontFile);
    },
    backSetter(e) {
      this.backFile = e.target.files[0];
      this.backSrc = URL.createObjectURL(this.backFile);
    },
    async uploadShablon() {
      try {
        this.busy = true;
        const formData = new FormData();
        const fields = this.allData.fields;
        fields.filter((item) => item.type === "position")[0].x_axis = this.elements.position.x_axis;
        fields.filter((item) => item.type === "position")[0].y_axis = this.elements.position.y_axis;
        fields.filter((item) => item.type === "position")[0].color = this.elements.position.color;
        fields.filter((item) => item.type === "position")[0].font_size = this.elements.position.font_size;
        fields.filter((item) => item.type === "position")[0].is_enabled = this.elements.position.is_enabled;

        fields.filter((item) => item.type === "organization")[0].x_axis = this.elements.company.x_axis;
        fields.filter((item) => item.type === "organization")[0].y_axis = this.elements.company.y_axis;
        fields.filter((item) => item.type === "organization")[0].color = this.elements.company.color;
        fields.filter((item) => item.type === "organization")[0].font_size = this.elements.company.font_size;
        fields.filter((item) => item.type === "organization")[0].is_enabled = this.elements.company.is_enabled;

        fields.filter((item) => item.type === "id")[0].x_axis = this.elements.id.x_axis;
        fields.filter((item) => item.type === "id")[0].y_axis = this.elements.id.y_axis;
        fields.filter((item) => item.type === "id")[0].color = this.elements.id.color;
        fields.filter((item) => item.type === "id")[0].font_size = this.elements.id.font_size;
        fields.filter((item) => item.type === "id")[0].is_enabled = this.elements.id.is_enabled;

        fields.filter((item) => item.type === "image")[0].x_axis = this.elements.organization.x_axis;
        fields.filter((item) => item.type === "image")[0].y_axis = this.elements.organization.y_axis;
        fields.filter((item) => item.type === "image")[0].is_enabled = this.elements.organization.is_enabled;

        // fields.filter((item) => item.type === "qr")[0].x_axis = this.elements.qr.x_axis;
        // fields.filter((item) => item.type === "qr")[0].y_axis = this.elements.qr.y_axis;

        fields.filter((item) => item.type === "first_name")[0].x_axis = this.elements.firstName.x_axis;
        fields.filter((item) => item.type === "first_name")[0].y_axis = this.elements.firstName.y_axis;
        fields.filter((item) => item.type === "first_name")[0].color = this.elements.firstName.color;
        fields.filter((item) => item.type === "first_name")[0].font_size = this.elements.firstName.font_size;
        fields.filter((item) => item.type === "first_name")[0].is_enabled = this.elements.firstName.is_enabled;

        fields.filter((item) => item.type === "last_name")[0].x_axis = this.elements.secondName.x_axis;
        fields.filter((item) => item.type === "last_name")[0].y_axis = this.elements.secondName.y_axis;
        fields.filter((item) => item.type === "last_name")[0].color = this.elements.secondName.color;
        fields.filter((item) => item.type === "last_name")[0].font_size = this.elements.secondName.font_size;
        fields.filter((item) => item.type === "last_name")[0].is_enabled = this.elements.secondName.is_enabled;

        const jsoned = JSON.stringify(fields);

        if (this.backFile) {
          formData.append("back_image", this.backFile);
        }
        if (this.frontFile) {
          formData.append("front_image", this.frontFile);
        }
        formData.append("created_at", this.allData.created_at);
        formData.append("event", this.allData.event);
        formData.append("id", this.allData.id);
        formData.append("fields", jsoned);

        await instance.patch(
            `/event/template/${this.allData.id}/`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.accessToken}`,
              },
            }
        );

        this.$toast.success("Шаблон изменён");
      } catch (error) {
        console.log(error);
        this.$toast.error(error);
      } finally {
        this.busy = false;
        window.location.reload();
      }
    }
  },
  computed: {
    logoY() {
      return {
        translateY: `${this.organization.y_axis}px`,
      };
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch("getShablon", localStorage.getItem("event"));
      this.allData = this.$store.state.shablon;
      //back_image
      if (this.allData.back_image) {
        this.backSrc = this.allData.back_image;
      }

      //front_image
      if (this.allData.front_image) {
        this.frontSrc = this.allData.front_image;
      }

      //position
      const position = this.allData.fields.filter(
          (pos) => pos.type === "position"
      )[0];
      this.elements.position.y_axis = position.y_axis / 2;
      this.elements.position.x_axis = position.x_axis / 2;
      this.elements.position.color = position.color;
      this.elements.position.font_size = position.font_size;
      this.elements.position.is_enabled = position.is_enabled;

      //image
      const data = this.allData.fields.filter((im) => im.type === "image")[0];
      this.elements.organization.y_axis = data.y_axis / 2;
      this.elements.organization.x_axis = data.x_axis / 2;
      this.elements.organization.is_enabled = data.is_enabled;

      //id
      const ided = this.allData.fields.filter((item) => item.type === "id")[0];
      this.elements.id.y_axis = ided.y_axis / 2;
      this.elements.id.x_axis = ided.x_axis / 2;
      this.elements.id.color = ided.color;
      this.elements.id.font_size = ided.font_size;
      this.elements.id.is_enabled = ided.is_enabled;

      //organization
      const org = this.allData.fields.filter(
          (data) => data.type === "organization"
      )[0];
      this.elements.company.y_axis = org.y_axis / 2;
      this.elements.company.x_axis = org.x_axis / 2;
      this.elements.company.color = org.color;
      this.elements.company.font_size = org.font_size;
      this.elements.company.is_enabled = org.is_enabled;

      // first name
      const firstNamed = this.allData.fields.filter(
          (i) => i.type === "first_name"
      )[0];
      this.elements.firstName.y_axis = firstNamed.y_axis / 2;
      this.elements.firstName.x_axis = firstNamed.x_axis / 2;
      this.elements.firstName.color = firstNamed.color;
      this.elements.firstName.font_size = firstNamed.font_size;
      this.elements.firstName.is_enabled = firstNamed.is_enabled;

      // last name
      const second = this.allData.fields.filter(
          (i) => i.type === "last_name"
      )[0];
      this.elements.secondName.y_axis = second.y_axis / 2;
      this.elements.secondName.x_axis = second.x_axis / 2;
      this.elements.secondName.color = second.color;
      this.elements.secondName.font_size = second.font_size;
      this.elements.secondName.is_enabled = second.is_enabled;

    } catch (error) {
      this.$toast.error(error.response.data.message)
    } finally {
      this.busy = false;
    }
  },
};
</script>

<style scoped lang="scss">
.positionAxis {
  width: 100%;
  display: flex;
  justify-content: center;

  & > div {
    width: 45% !important;
  }
}

.logotype {
  width: 250px;
  object-fit: cover;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Main {
  height: 100vh;
  width: 100%;
  background: #f8f7ff;
}

.uploadBtn {
  padding: 3px 0;
  width: 60%;
  border: 1px solid #f8f7ff;
  background: #354b9c;
  border-radius: 5px;
  margin: 10px auto;
  text-transform: uppercase;
  color: #fff;
}

.cart_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div {
    position: relative;
    color: #000 !important;
    margin: 10px auto;
  }
}

label.title {
  text-align: center !important;
}

.beyjXCenter {
  position: absolute;
  cursor: move;
  user-select: none;
  z-index: 1;
}

.inputt {
  width: 100%;
}

.only-label {
  min-width: 200px;
}

.form-control {
  text-align: center;
}

.axis-input {
  width: 100px;
}

.boxx {
  min-width: 400px;
  overflow: scroll;
}

.custom-control {
  text-align: center;
}
</style>
