import { render, staticRenderFns } from "./PaymentResponsePage.vue?vue&type=template&id=03bed1cc&scoped=true&"
import script from "./PaymentResponsePage.vue?vue&type=script&lang=js&"
export * from "./PaymentResponsePage.vue?vue&type=script&lang=js&"
import style0 from "./PaymentResponsePage.vue?vue&type=style&index=0&id=03bed1cc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03bed1cc",
  null
  
)

export default component.exports